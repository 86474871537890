import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReviewInfoModel } from '@shared/models/social-post.model';

import { RatingStarsReadOnlyComponent } from '../rating-stars/rating-stars-readonly.component';
import { TextExpandComponent } from '@components/text-expand.component';

@Component({
  selector: 'cigar-review',
  standalone: true,
  imports: [CommonModule, TextExpandComponent, RatingStarsReadOnlyComponent],
  template: `
    <div
      *ngIf="review.Rating"
      class="overflow-hidden relative flex items-center space-x-3 rounded-lg border border-solid border-gray-100  dark:border-gray-700 shadow-sm "
    >
      <div class="flex w-full">
        <div
          class="p-1 flex-1 items-center flex flex-col justify-center border-0 border-r border-solid border-gray-100 dark:border-gray-700 "
        >
          <div class="uppercase">overall</div>
          <span class="text-2xl">{{ review.Rating | number : '1.1-2' }}</span>
          <cs-rating-stars-readonly
            fillColor="#FDC70C"
            borderColor="#FDC70C"
            [rating]="review.Rating"
            [size]="16"
          />
        </div>
        <div class="p-1 flex flex-col justify-center">
          <div class="flex gap-1">
            <div class="flex-1 uppercase">draw</div>
            <cs-rating-stars-readonly
              class="mx-1"
              fillColor="#FDC70C"
              borderColor="#FDC70C"
              [rating]="review.DrawRating"
              [size]="16"
            />
          </div>
          <div class="flex gap-1">
            <div class="flex-1 uppercase">appearance</div>
            <cs-rating-stars-readonly
              class="mx-1"
              fillColor="#FDC70C"
              borderColor="#FDC70C"
              [rating]="review.AppearanceRating"
              [size]="16"
            />
          </div>
          <div class="flex gap-1">
            <div class="flex-1 uppercase">burn</div>
            <cs-rating-stars-readonly
              class="mx-1"
              fillColor="#FDC70C"
              borderColor="#FDC70C"
              [rating]="review.BurnRating"
              [size]="16"
            />
          </div>
          <div class="flex gap-1">
            <div class="flex-1 uppercase">aroma</div>
            <cs-rating-stars-readonly
              class="mx-1"
              fillColor="#FDC70C"
              borderColor="#FDC70C"
              [rating]="review.AromaRating"
              [size]="16"
            />
          </div>
          <div class="flex gap-1">
            <div class="flex-1 uppercase">taste</div>
            <cs-rating-stars-readonly
              class="mx-1"
              fillColor="#FDC70C"
              borderColor="#FDC70C"
              [rating]="review.TasteRating"
              [size]="16"
            />
          </div>
        </div>
      </div>
    </div>
    <blockquote class="text-sm !font-medium" *ngIf="review?.Comment">
      <cs-text-expand
        [text]="review.Comment"
        [maxLength]="400"
      ></cs-text-expand>
    </blockquote>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      blockquote {
        border-left: 5px solid var(--ion-color-step-150);
        padding: 10px 20px;
        margin: 0.5em 0;
      }
    `,
  ],
})
export class CigarReviewComponent {
  @Input() review: ReviewInfoModel;
  constructor() {}
}
