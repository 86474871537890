import { addIcons } from 'ionicons';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IonButton, IonIcon } from '@ionic/angular/standalone';

@Component({
  selector: 'cs-text-expand',
  standalone: true,
  imports: [CommonModule, IonButton, IonIcon],
  template: `
    <span class="whitespace-pre-line" *ngIf="!isExpanded"
      >{{ shortText }}<span *ngIf="shouldShowButton">...</span>
    </span>
    <span class="whitespace-pre-line" *ngIf="isExpanded">{{ text }}</span>
    <div class="flex justify-center" *ngIf="shouldShowButton">
      <ion-button
        fill="outline"
        size="small"
        color="dark"
        mode="ios"
        (click)="toggleView($event)"
      >
        <ion-icon
          slot="start"
          [name]="isExpanded ? 'chevron-up-outline' : 'chevron-down-outline'"
        ></ion-icon>

        {{ isExpanded ? 'less' : 'more' }}
      </ion-button>
    </div>
  `,
})
export class TextExpandComponent implements OnChanges {
  @Input() text: string = '';
  @Input() maxLength: number = 100; // Maximum length of text before showing "See More"

  isExpanded: boolean = false;
  shortText: string = '';
  shouldShowButton: boolean = false;

  constructor() {
    addIcons({ chevronDownOutline, chevronUpOutline });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['text'].currentValue) {
      this.isExpanded = false; // Reset expanded state
      this.updateView();
    }
  }

  updateView() {
    if (this.text.length > this.maxLength) {
      this.shortText = this.text.substring(0, this.maxLength);
      this.shouldShowButton = true;
    } else {
      this.shortText = this.text;
      this.shouldShowButton = false;
    }
  }

  toggleView(e: Event) {
    e.stopPropagation();
    this.isExpanded = !this.isExpanded;
  }
}
